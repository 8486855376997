import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface TypeaheadOptions {
  label: string
  value: string
}

@Component({
  selector: 'fw-typeahead',
  template: `
    <mat-form-field appearance="outline" class="form-field">
      <mat-chip-list #chipList [attr.aria-label]="placeholder" class="mat-chip-list-stacked">
        <mat-chip *ngFor="let chipValue of value; index as chipIndex"
          (removed)="removeChip(chipIndex)"
          class="chip">
          <span class="chip-label">{{ getLabelFor(chipValue) }}</span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          #chipInput
          [formControl]="typeaheadInput"
          [placeholder]="value.length > 0 ? '' : placeholder"
          [matAutocomplete]="autoOptions"
          [matChipInputFor]="chipList"
          class="chip-input">
      </mat-chip-list>
    </mat-form-field>
    <mat-autocomplete #autoOptions="matAutocomplete" (optionSelected)="selectChip($event)" panelWidth="auto">
      <mat-option *ngFor="let option of typeaheadOptions$ | async" [value]="option.value">
        {{ option.label }} 
        <span *ngIf="option.email" class="text-muted user-email">{{ option.email }}</span>
      </mat-option>
    </mat-autocomplete>
  `,
  styles: [`
    .chip {
      display: flex;
      width: calc(100% - 8px) !important;
      min-height: 20px;
      padding: 0 0 0 12px !important;
      font-size: 10px;
    }
    .chip-input {
      flex-basis: 1.2em;
      width: calc(100% - 8px);
    }
    .chip-label {
      flex: 1;
    }
    .form-field {
      min-width: 180px;
    }
    .mat-option {
      line-height: 18px;
    }
    .user-email {
      font-size: 11px;
      margin: 0;
      display: block;
    }
  `],
})
export class FwTypeaheadComponent implements OnInit {
  @Input() value: string[]
  @Output() valueChange = new EventEmitter<string[]>();

  @Input() options: TypeaheadOptions[]
  @Input() placeholder = ''
  @Input() required = false

  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  public typeaheadInput = new FormControl()
  public typeaheadOptions$: Observable<TypeaheadOptions[]>

  ngOnInit(): void {
    this.typeaheadOptions$ = this.typeaheadInput.valueChanges.pipe(
      startWith(''),
      map(value => {
        const lowerValue = value.toLowerCase();
        return this.options.filter(({ label }) => label.toLowerCase().includes(lowerValue));
      }),
    );
  }

  getLabelFor(value: string): string {
    const option = this.options.find(option => option.value === value) || { label: '' };
    return option.label;
  }

  selectChip(event: MatAutocompleteSelectedEvent): void {
    const { option } = event;
    this.value = [ ...this.value, option.value ];
    this.valueChange.next(this.value);
    this.typeaheadInput.setValue('');
    this.chipInput.nativeElement.value = '';
  }

  removeChip(index: number): void {
    this.value = [ ...this.value.slice(0, index), ...this.value.slice(index + 1) ];
    this.valueChange.next(this.value);
  }
}
