import { Component, Input } from '@angular/core';
import { Session } from '../../shared/models/session.model';

@Component({
  selector: 'subject-session',
  template: `
    <div class="card no-padding">
      <h1 class="font-card-header has-button">
        <a (click)="open = !open" class="session-label">{{ session.label }}</a>
        <i *ngIf="writable" class="fa fa-ellipsis-v task-menu-btn pull-right" [matMenuTriggerFor]="appMenu"></i>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="createAcquisition(session)">Create Acquisition</button>
          <button mat-menu-item (click)="deleteSession(session)">Delete Session</button>
        </mat-menu>
      </h1>
      <div [hidden]="!open">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [`
    .session-label {
      display: inline-block;
      margin: 8px 0;
    }
  `]
})

export class SubjectSessionComponent {
  @Input() session: Session
  @Input() writable = false
  @Input() deleteSession: (session: Session) => void
  @Input() createAcquisition: (session: Session) => void
  public open = true
}
