import { TreeQuery } from '../../shared/models/tree-query.model';

export const subjectTreeQuery: TreeQuery = {
  "subjects": {
    "fields": [
      "code",
      "files",
      "label",
      "project",
      "notes",
      "_id",
    ], 
    "sessions": {
      "fields": [
        "label",
        "notes",
        "parents",
        "permissions",
        "tags",
        "timestamp",
        "_id",
      ],
      "acquisitions": {
        "fields": [
          "created", 
          "info", 
          "info_exists",
          "label", 
          "modified",
          "tags", 
          "timestamp",
          "_id",
        ],
        "files": {
          "fields": [
            "classification", 
            "created",
            "hash",
            "info",
            "info_exists",
            "mimetype",
            "modality",
            "modified",
            "name",
            "path",
            "size",
            "type",
            "zip_member_count",
          ],
          "join-origin": true,
        },
      },
    },
  }
};
