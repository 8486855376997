import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatDialog } from '@angular/material/dialog';
import { downgradeInjectable } from '@angular/upgrade/static';

import APP_MODULE from '../../app.module.ajs';
import { ConfirmDialog } from './confirm-dialog.component';

@NgModule({
  declarations: [
    ConfirmDialog,
  ],
  entryComponents: [
    ConfirmDialog,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class DialogModule { }

/**
 * This must be provided at the app root.
 */
export const dialogDefaults = {
  provide: MAT_DIALOG_DEFAULT_OPTIONS,
  useValue: {
    disableClose: true,
    hasBackdrop: true,
  }
};

/**
 * Allow AngularJS controllers to launch Material dialogs.
 */
declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'matDialog',
    downgradeInjectable(MatDialog)
  );
