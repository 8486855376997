import { Injectable } from '@angular/core';
import get from 'lodash/get';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { shareReplay, map, switchMap, first } from 'rxjs/operators';

import { DashboardService } from '../ajs-upgraded-providers';
import { FlywheelService } from '../flywheel.service';
import { ProfileService } from '../profile/profile.service';
import { VisualQuery } from '../search/advanced-query/visual-query.utils';
import { SessionService } from '../sessions/session.service';
import { RoleMatrix } from '../shared/enums/container-access.enum';
import { RequestState, isInitialized, isLoading } from '../shared/enums/request-state.enum';
import { SavedSearch } from '../shared/models/search/saved-search.model';
import { SearchFacets } from '../shared/models/search/search-facets.model';
import { SearchQuery, SearchReturnType, SearchFilter } from '../shared/models/search/search-query.model';
import { SearchResult, SearchResultResponse } from '../shared/models/search/search-results.model';
import { SidebarModule } from './sidebar.module';

@Injectable({
  providedIn: SidebarModule
})
export class SidebarService {
  readonly context = {
    current: 'session' as SearchReturnType,
    fn: null as Function,
  }
  public link = {
    active: 'dashboard'
  }
  public state = 'default'
  public oldStateParams: object = null;

  readonly search = {
    allData: false,
    facets: null as SearchFacets,
    filtered: {},
    query: '',
    pageSize: 100,
    projectLabel: '',
    projectExactMatch: '',
    response: {},
    selectAll: false,
    selectedSession: null,
    sidebarFilters: [] as SearchFilter[],
    structuredQuery: '',
    tableFilters: [] as SearchFilter[],
    total: 0,
    visualQuery: null as VisualQuery,
    resetSearchState: null,
    enterSearchState: null,
    clearSessionTimestampFilters: null,
  }

  public filters = {}
  public activations = {}

  private savedSearches = {
    state: RequestState.Empty,
    subject: new ReplaySubject<SavedSearch[]>(),
  }
  readonly savedSearches$ = this.savedSearches.subject.asObservable()

  private error = new Subject<Error>()
  readonly error$ = this.error.asObservable()

  constructor(
    private dashboardService: DashboardService,
    private flywheel: FlywheelService,
    private profileService: ProfileService,
    private sessionService: SessionService,
  ) { }

  public setActivation(name: string, fn: Function): void {
    this.activations[name] = fn;
  }

  public toggleCollapsed(): void {
    this.state = this.state === 'collapsed' ? 'default' : 'collapsed';
  }

  public buildSearchQuery(): SearchQuery {
    const filters = [...this.search.sidebarFilters, ...this.search.tableFilters];
    if (this.search.projectLabel) {
      if (this.search.projectLabel === this.search.projectExactMatch) {
        filters.push({
          terms: {
            'project.label': [this.search.projectLabel]
          }
        });
      } else {
        filters.push({
          match: {
            'project.label': this.search.projectLabel
          }
        });
      }
    }
    const payload: SearchQuery = {
      filters: filters,
      return_type: this.context.current,
      all_data: this.search.allData || false,
    };
    if (this.search.structuredQuery) {
      payload.structured_query = this.search.structuredQuery;
    } else {
      payload.search_string = this.search.query;
    }
    return payload;
  }

  public performSearch(resultCount?: number): Observable<SearchResultResponse> {
    const payload = this.buildSearchQuery();
    const params = {
      facets: true,
      size: Math.min(resultCount || this.search.pageSize, 10000), // 10K is the limit on the API
    };
    this.search.selectAll = false;
    this.sessionService.clearCheckedSessions();
    const observable = combineLatest(
      this.flywheel.dataexplorer.search(params, payload),
      this.profileService.getProfile().pipe(first(data => Boolean(data))), // ensure profile is loaded
    ).pipe(
      map(([response]) => {
        response.results.forEach((responseItem: SearchResult & ResultExtras) => {
          if (responseItem._source) {
            responseItem._source.roleMatrix = this.profileService.getRoleMatrix(responseItem._source);
            if (this.context.current === 'session' && responseItem._source) {
              if (responseItem._source.session) {
                responseItem.elasticId = responseItem._id;
                responseItem._id = responseItem._source.session._id;
              }
              responseItem.project = responseItem.project || {};
              responseItem.project.roleMatrix = this.profileService.getRoleMatrix(responseItem._source);
            }
          }
        });
        if (this.context.current === 'session') {
          this.dashboardService.setSessions(response.results);
        }
        this.search.response = response;
        this.search.filtered = response;
        this.search.total = getTotalResults(response.facets.facets, this.context.current);
        return response;
      }),
      shareReplay(1), // only one request please
    );
    observable.subscribe(); // make it HOT
    return observable;
  }

  public resetSearchState(): void {
    this.dashboardService.setSessions([]);
    this.search.selectedSession = null;
    this.search.projectLabel = '';
    this.state = 'default';
  }

  public getSavedSearches({ refresh = false } = {}): Observable<SavedSearch[]> {
    if (isLoading(this.savedSearches.state)) {
      return this.savedSearches$;
    }
    if (!isInitialized(this.savedSearches.state) || refresh) {
      this.savedSearches.state = RequestState.Fetching;
      this.flywheel.dataexplorer.queries.query().subscribe(data => {
        this.savedSearches.subject.next(data.slice().reverse());
      }, error => {
        this.error.next(error);
      }, () => {
        this.savedSearches.state = RequestState.Loaded;
      });
    }
    return this.savedSearches$;
  }

  public saveSearch(input: SavedSearchInput): Observable<SavedSearch> {
    const observable = this.profileService.getProfile().pipe(
      first(data => Boolean(data)),
      switchMap(profile => {
        const query = this.buildSearchQuery();
        const search: Partial<SavedSearch> = {
          parent: {
            type: 'user',
            id: profile._id,
          },
          ...input,
          search: {
            filters: query.filters,
            return_type: query.return_type,
            all_data: query.all_data,
            ...input.search,
          }
        };
        return this.flywheel.dataexplorer.queries.post(search);
      }),
      shareReplay(1),
    );
    observable.subscribe(() => {
      this.getSavedSearches({ refresh: true });
    });
    return observable;
  }
}

interface SavedSearchInput {
  label: string
  search: Partial<SearchQuery>
}

interface ResultExtras {
  elasticId?: string
  project?: {
    roleMatrix?: RoleMatrix
  }
}

function getTotalResults(searchFacets: SearchFacets, searchContext: SearchReturnType): number {
  switch (searchContext) {
    case 'session':
      return get(searchFacets, 'session_count.value', 0);
    case 'acquisition':
      return get(searchFacets, 'acquisition_count.value', 0);
    case 'file':
      return get(searchFacets, 'file_count.value', 0);
    case 'analysis':
      return get(searchFacets, 'analysis_count.value', 0);
    default:
      return 0;
  }
}
