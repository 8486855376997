import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { downgradeComponent } from '@angular/upgrade/static';

import { AdvancedQueryComponent } from './advanced-query/advanced-query.component';
import { FlyqlEditorComponent } from './flyql-editor/flyql-editor.component';
import { SavedQueriesComponent } from './saved-queries/saved-queries.component';
import { SaveQueryComponent } from './saved-queries/save-query.component';
import { MaterialModule } from '../shared/material.module';
import { TrainingSetDialog } from './training-set/training-set-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatTableModule,
  ],
  declarations: [
    AdvancedQueryComponent,
    FlyqlEditorComponent,
    SavedQueriesComponent,
    SaveQueryComponent,
    TrainingSetDialog,
  ],
  entryComponents: [
    AdvancedQueryComponent,
    SaveQueryComponent,
    TrainingSetDialog,
  ],
})
export class SearchModule { }

declare const angular: angular.IAngularStatic;
angular.module('search', [])
  .directive(
    'advancedQuery',
    downgradeComponent({ component: AdvancedQueryComponent }) as angular.IDirectiveFactory
  );
