import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

import APP_MODULE from '../app.module.ajs';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  error(error: Error): void {
    console.error(error); // eslint-disable-line no-console
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'loggingService',
    downgradeInjectable(LoggingService)
  );
