import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, scan, startWith, switchMap } from 'rxjs/operators';

import { NotificationService } from '../../ajs-upgraded-providers';
import { FlywheelService } from '../../flywheel.service';
import { ProfileService } from '../../profile/profile.service';
import { ProjectService } from '../../projects/project.service';
import { ContainerAccess } from '../../shared/enums/container-access.enum';
import { arrayLengthMin } from '../../shared/form.utils';
import { Group } from '../../shared/models/group.model';
import { Project } from '../../shared/models/project.model';
import { SearchField } from '../../shared/models/search/search-field.model';
import { SearchQuery } from '../../shared/models/search/search-query.model';

type TrainingSetPayload = SearchQuery & {
  file_count: number
  files?: {
    _id: string
  }
}

type GroupOption = Group & {
  projects: Project[]
}

@Component({
  templateUrl: './training-set-dialog.component.html',
})
export class TrainingSetDialog {
  public form: FormGroup
  public groups$: Observable<GroupOption[]>
  public fields$: Observable<SearchField[]>
  public outputFilePath$: Observable<string>
  public labelValueStream$ = new Subject<string>()

  @ViewChild('labelChipInput') labelChipInput: ElementRef;

  private projects$ = this.projectService.getProjects()
  private defaultFilename = `training_set_${new Date().toISOString()}.json`

  constructor(
    @Inject(MAT_DIALOG_DATA) public payload: Partial<TrainingSetPayload>,
    private dialogRef: MatDialogRef<TrainingSetDialog>,
    private flywheel: FlywheelService,
    private notifications: NotificationService,
    private profileService: ProfileService,
    private projectService: ProjectService,
  ) {
    this.groups$ = combineLatest(
      this.projects$.pipe(map(projects => {
        const writable = projects.filter(project =>
          this.profileService.getRoleMatrix(project)[ContainerAccess.ReadWrite]);
        return sortBy(writable, project => (project.label || project._id).toLowerCase());
      })),
      this.projectService.getGroups().pipe(map(groups =>
        new Map(groups.map(group => ([ group._id, group ] as [ string, Group ])))
      )),
      this.profileService.getProfile().pipe(filter(data => Boolean(data))),
    ).pipe(
      map(([ writableProjects, groupMap ]) => {
        const groupedProjects = groupBy(writableProjects, 'group');
        const groupOptions = Object.entries(groupedProjects).map(([ groupId, projects ]) => {
          const group = groupMap.get(groupId);
          const label = group ? (group.label || group._id) : groupId;
          return Object.assign({}, group, { label, projects: projects as Project[] });
        });
        return sortBy(groupOptions, group => group.label.toLowerCase());
      })
    )

    this.form = new FormGroup({
      projectId: new FormControl(null, Validators.required),
      labels: new FormControl([], arrayLengthMin(1)),
      labelInput: new FormControl(''),
      filename: new FormControl(this.defaultFilename),
    });

    this.fields$ = this.form.controls.labelInput.valueChanges.pipe(
      startWith(this.form.controls.labelInput.value),
      filter(data => Boolean(data)),
      switchMap(value => this.flywheel.dataexplorer.fields({ field: value })),
    );

    this.outputFilePath$ = combineLatest(
      this.form.controls.projectId.valueChanges,
      this.form.controls.filename.valueChanges.pipe(startWith(this.defaultFilename)),
      this.projects$,
    ).pipe(
      map(([ projectId, filename, allProjects ]) => {
        const project = allProjects.find(project => project._id === projectId);
        return project ? `${project.label} / ${filename || this.defaultFilename}` : '';
      })
    );

    /**
     * Handle chip suggestion selection and offroad enter/blur values in one stream.
     * The events for the chip autocomplete and its input stomp on one another in an
     * inconsistent order, so we pick the longest value in the stream over a short
     * period, add it to the chip array, then reset the stream with an empty value.
     */
    this.labelValueStream$.pipe(
      scan((specific, value) => {
        this.form.controls.labels.setErrors(null); // pause validation
        if (value) {
          return value.length > specific.length ? value : specific;
        }
        return value;
      }),
      debounceTime(200),
      filter(value => value !== ''),
    ).subscribe(value => {
      const labels = this.form.controls.labels;
      labels.setValue(uniq([ ...labels.value, value ]));
      this.labelChipInput.nativeElement.value = '';
      this.labelValueStream$.next(''); // reset the stream
    });
  }

  removeChip(index: number): void {
    const labels = this.form.controls.labels;
    labels.setValue([ ...labels.value.slice(0, index), ...labels.value.slice(index + 1) ]);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onCreate(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = {
      ...this.payload,
      labels: this.form.get('labels').value,
      output: {
        type: 'project',
        id: this.form.get('projectId').value,
      },
      filename: this.form.get('filename').value || this.defaultFilename,
    };
    this.form.disable();
    this.flywheel.dataexplorer.training(payload).subscribe({
      next: () => {
        this.notifications.success('dataexplorer.saveTrainingSet.success');
        this.dialogRef.close();
      },
      error: error => {
        console.log(error); // eslint-disable-line no-console
        this.notifications.error('dataexplorer.saveTrainingSet.error');
        this.form.enable();
      },
    });
  }
}
