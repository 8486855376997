import { NgModule } from '@angular/core';

import { GearService } from './gear.service';

@NgModule({
  providers: [
    GearService,
  ],
})
export class GearModule { }

declare const angular: angular.IAngularStatic;
angular
  .module('gear', [
  ]);
