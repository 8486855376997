import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { downgradeComponent } from '@angular/upgrade/static';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import APP_MODULE from '../app.module.ajs';
import { apiConfigProvider, flywheelConfigProvider, profileServiceProvider } from '../ajs-upgraded-providers';
import { FwAccordionComponent } from './fw-accordion/fw-accordion.component';
import { SubjectSessionComponent } from './subject-tab/subject-session';
import { FwAvatarComponent } from './fw-avatar/fw-avatar.component';
import { FwInputComponent } from './fw-input/fw-input.component';
import { FwSelectComponent } from './fw-select/fw-select.component';
import { FwTypeaheadComponent } from './fw-typeahead/fw-typeahead.component';
import { MaterialModule } from '../shared/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  declarations: [
    FwAccordionComponent,
    SubjectSessionComponent,
    FwAvatarComponent,
    FwInputComponent,
    FwSelectComponent,
    FwTypeaheadComponent
  ],
  entryComponents: [
    FwAccordionComponent,
    SubjectSessionComponent,
    FwAvatarComponent,
    FwInputComponent,
    FwSelectComponent,
    FwTypeaheadComponent
  ],
  providers: [
    apiConfigProvider,
    flywheelConfigProvider,
    profileServiceProvider,
  ]
})
export class DirectivesModule { }

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .directive(
    'fwAccordion',
    downgradeComponent({ component: FwAccordionComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'subjectSession',
    downgradeComponent({ component: SubjectSessionComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'fwAvatar',
    downgradeComponent({ component: FwAvatarComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'fwInput',
    downgradeComponent({ component: FwInputComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'fwSelect',
    downgradeComponent({ component: FwSelectComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'fwTypeahead',
    downgradeComponent({ component: FwTypeaheadComponent }) as angular.IDirectiveFactory
  );
