<button mat-icon-button mat-dialog-close class="close-button">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
  Download Project
</div>
<div mat-dialog-content class="form-block-fields">
  <div class="mat-form-field">
    <mat-checkbox (change)="toggleFullProjectDownload()" [checked]="fullProjectDownload" class="mat-label-header"><strong>Download Full Project</strong></mat-checkbox>
  </div>
  <div *ngIf="fullProjectDownload">
    <span>
      <mat-checkbox (change)="toggleInclude('metadata')" class="mat-download-checkbox" [checked]="include.metadata">
        Include Metadata
      </mat-checkbox>
    </span>
    <span
      matTooltipPosition="right" 
      matTooltip='Select this option to download your project metadata and all metadata associated with your selected files and their related containers'>
      <mat-icon class="help-icon">help_outline</mat-icon>
    </span>
  </div>
  <div *ngIf="fullProjectDownload">
    <span>
      <mat-checkbox (change)="toggleInclude('analyses')" class="mat-download-checkbox" [checked]="include.analyses">
        Include Analysis
      </mat-checkbox>
    </span>
    <span
      matTooltipPosition="right" 
      matTooltip='Selecting this option will include all analyses inputs and/or outputs at every level of your project.  This option is only available when selecting “all files”'>
      <mat-icon class="help-icon">help_outline</mat-icon>          
    </span>
  </div>
  <mat-label class="mat-label-header">
    <strong>File Types</strong> 
    <span
      matTooltipPosition="right" 
      matTooltip='Select the types of files you’d like to download from your project.  Select “all files” to enable full download options.'>
      <mat-icon class="help-icon bottom">help_outline</mat-icon>
    </span>
  </mat-label>
  <div class="mat-form-field">
    <mat-checkbox (change)="toggleAllFiles()" [checked]="allTypes">All File Types</mat-checkbox>
  </div>
  <div *ngIf="!allTypes">
    <mat-form-field class="mat-form-field">
      <mat-select (selectionChange)="calculateTotals($event)" multiple placeholder="File Types">
        <mat-select-trigger>{{ downloadList.join(', ') }}</mat-select-trigger>
        <mat-option *ngFor="let item of types | keyvalue" [value]="item.value">
          {{ item.key }} 
          <span class="text-muted"> ({{ item.value.count }}) </span>
          <div class="pull-right">
            <span class="small-chip">
              {{ item.value.mb_total.toFixed(2) }} MB
            </span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align="end">
  <div class="footer-stats">
    <span class="small-chip">{{ total.files }} files selected</span>
    <span class="small-chip">{{ total.size.toFixed(2) }} MB</span>
  </div>
  <button mat-dialog-close mat-stroked-button>Cancel</button>
  <button mat-stroked-button class="primary" (click)="download()">
    <mat-icon class="material-icons xs bottom">cloud_download</mat-icon> Download Project
  </button>
</div>
