import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../shared/material.module';
import { BrowserWarningComponent } from './browser-warning.component';


@NgModule({
  declarations: [
    BrowserWarningComponent,
  ],
  entryComponents: [
    BrowserWarningComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
})
export class LoginModule { }

declare const angular: angular.IAngularStatic;
angular.module('login', []);
