import get from 'lodash/get';

import { File } from '../shared/models/file.model';

export enum FileType {
  code = 'code',
  csv = 'csv',
  dicom = 'dicom',
  eeg = 'eeg',
  embeddedhtml = 'embeddedhtml',
  image = 'image',
  markdown = 'markdown',
  markup = 'markup',
  montage = 'montage',
  nifti = 'nifti',
  obj = 'obj',
  pdf = 'pdf',
  text = 'text',
  video = 'video',
}

/**
 * Get everything after the first dot in a filename, or the specified number of segments.
 */
export function getExtension(filename: string, segments = 0): string {
  const match = (filename || '').match(/\.([^?$]+)/);
  const extension = match && match[1];
  if (extension) {
    return extension.split('.').slice(-segments).join('.');
  }
  return null;
}

/**
 * Get the last part of a multi-part extension.
 */
export function getLastExtension(filename: string): string {
  return getExtension(filename, 1);
}

export function getViewerType(file: File): FileType {
  if (!file) {
    return null;
  }
  const fileType = (file.type || '').toLowerCase();
  const fileName = (file.name || '').toLowerCase();
  switch (fileType) {
    case 'bval':
    case 'bvec':
      return FileType.code;
    case 'dicom':
      if (get(file, 'classification.Intent', []).includes('Non-Image')) {
        return null;
      }
      return FileType.dicom;
    case 'eeg':
    case 'eeg data':
    case 'eeg marker':
    case 'eeg header':
      return FileType.eeg;
    case 'nifti':
      return FileType.nifti;
    case 'qa':
      switch (getLastExtension(fileName)) {
        case 'png':
          return FileType.image;
        case 'html':
          return FileType.markup;
        case 'json':
          return FileType.code;
        default:
          return null;
      }
    case 'image':
      return FileType.image;
    case 'markup':
      return FileType.markup;
    case 'markdown':
      return FileType.markdown;
    case 'montage':
      return FileType.montage;
    case 'log':
    case 'text':
      return FileType.text;
    case 'pdf':
      return FileType.pdf;
    case 'source code':
      return FileType.code;
    case 'tabular data':
      return FileType.csv;
    case 'video':
      return FileType.video;
    case 'obj':
      return FileType.obj;
  }
  switch (getExtension(fileName, 2)) {
    case 'html.zip':
      return FileType.embeddedhtml;
  }
  return null;
}
