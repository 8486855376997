import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import groupBy from 'lodash/groupBy';
import { ReplaySubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import APP_MODULE from '../app.module.ajs';
import { FlywheelService } from '../flywheel.service';
import { GearModule } from './gear.module';
import { CachingService } from '../shared/caching.service';
import { RequestState } from '../shared/enums/request-state.enum';
import { Gear } from '../shared/models/gear.model';

interface GearsByName {
  [name: string]: Gear[]
}

@Injectable({
  providedIn: GearModule,
})
export class GearService extends CachingService {
  private versions = {
    state: RequestState.Empty,
    subject: new ReplaySubject<GearsByName>(),
    request: this.flywheel.gears.query({ all_versions: true }).pipe(map(gears => groupBy(gears, 'gear.name'))),
  }
  readonly versions$ = this.versions.subject.asObservable()

  constructor(private flywheel: FlywheelService) {
    super();
  }

  getAllVersions({ refresh = false } = {}): Observable<GearsByName> {
    return this.getCacheableResource(this.versions, this.versions$, { refresh });
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'gearService',
    downgradeInjectable(GearService)
  );
