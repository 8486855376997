import { UserSettings, defaultSessionSettings } from "../shared/models/user-settings.model";

const USER_SETTINGS_KEY = 'flywheelSettings';

export function loadSettings(): UserSettings {
  let sessionSettings: UserSettings;
  try {
    sessionSettings = { ...defaultSessionSettings, ...JSON.parse(sessionStorage.getItem(USER_SETTINGS_KEY)) };
  } catch (error) {
    sessionSettings = defaultSessionSettings as UserSettings;
  }
  return sessionSettings;
}

export function saveSettings(settings: UserSettings): void {
  sessionStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(settings));
}
