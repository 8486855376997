import { Subject, Observable, Subscription } from 'rxjs';

import { RequestState, isLoading, isInitialized } from './enums/request-state.enum';

interface CachingResource<Type> {
  state: RequestState
  subject: Subject<Type>
  request: Observable<Type>
}

export abstract class CachingService {
  protected error = new Subject<Error>()
  readonly error$ = this.error.asObservable()

  /**
   * Ensure there is only one active subscription per resource.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private subscriptions: Map<CachingResource<any>, Subscription> = new Map()

  protected getCacheableResource<Type>(resource: CachingResource<Type>, cached$: Observable<Type>, { refresh = false } = {}): Observable<Type> {
    if (isLoading(resource.state)) {
      return cached$;
    }
    if (!isInitialized(resource.state) || refresh) {
      if (this.subscriptions.has(resource)) {
        this.subscriptions.get(resource).unsubscribe();
      }
      const previousState = resource.state;
      resource.state = RequestState.Fetching;
      const subscription = resource.request.subscribe(data => {
        resource.state = RequestState.Loaded;
        resource.subject.next(data);
      }, error => {
        resource.state = previousState;
        this.error.next(error);
      });
      this.subscriptions.set(resource, subscription);
    }
    return cached$;
  }
}
