import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { parse, Renderer } from 'marked';

import APP_MODULE from '../app.module.ajs';
import { ViewerModule } from './viewer.module';

@Injectable({
  providedIn: ViewerModule,
})
export class MarkdownService {
  private renderer = new Renderer()
  private options = {
    gfm: true,
    // highlight: () => {},
    renderer: this.renderer,
    // sanitize: true,
    // sanitizer: () => {},
    tables: true,
  }

  render(markdown = ''): string {
    return parse(markdown, this.options);
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'markdownService',
    downgradeInjectable(MarkdownService)
  );
