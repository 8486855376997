import { Component, Inject } from '@angular/core'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'; // eslint-disable-line @typescript-eslint/no-unused-vars

import { SearchQuery } from '../../shared/models/search/search-query.model';

@Component({
  template: `
    <h1 mat-dialog-title>Save Query</h1>
    <div mat-dialog-content>
      <form>
        <label class="field">
          <span class="mat-body-strong">Label</span>
          <mat-form-field appearance="outline">
            <input data-id="save-query-label-input" matInput name="label" [(ngModel)]="label" required>
            <mat-error>Label is required</mat-error>
          </mat-form-field>
        </label>
        <pre class="query">{{ query.structured_query }}</pre>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <button data-id="save-query-cancel-button" mat-button (click)="onCancel()">Cancel</button>
      <button data-id="save-query-save-button" mat-raised-button (click)="onSave()" [disabled]="!this.label" color="primary" cdkFocusInitial>Save</button>
    </div>
  `,
  styles: [`
    .mat-dialog-content {
      max-width: 500px;
      overflow: visible;
    }
    .field {
      display: flex;
      margin-bottom: 20px;
    }
    .field > span {
      align-self: center;
      min-width: 50px;
    }
    .field > .mat-form-field {
      flex: 1;
    }
    .query {
      white-space: pre-wrap;
      word-break: normal;
    }
  `]
})
export class SaveQueryComponent {
  public label = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public query: Partial<SearchQuery>,
    private dialogRef: MatDialogRef<SaveQueryComponent>,
  ) { }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onSave(): void {
    if (this.label) {
      this.dialogRef.close({
        label: this.label,
      });
    }
  }
}
