import { NgModule } from '@angular/core';

import { MarkdownService } from './markdown.service';

@NgModule({
  providers: [
    MarkdownService,
  ],
})
export class ViewerModule { }

declare const angular: angular.IAngularStatic;
angular
  .module('viewers', []);
