import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../shared/material.module';
import { ProjectDownloadComponent } from './project-download/project-download.component';
import { ProjectService } from './project.service';

@NgModule({
  declarations: [
    ProjectDownloadComponent,
  ],
  entryComponents: [
    ProjectDownloadComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  providers: [
    ProjectService,
  ],
})
export class ProjectModule { }
