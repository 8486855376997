import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import get from 'lodash/get';

@Component({
  selector: 'fw-input',
  template: `
    <mat-form-field appearance="outline">
      <input matInput
        [(formControl)]="control" 
        [placeholder]="placeholder" 
        [required]="required">
      <mat-error>
        {{ errorMessage }}
      </mat-error>
    </mat-form-field>
  `,
  styles: [`
    .mat-form-field {
      display: block;
    }
  `],
})

export class FwInputComponent {
  @Input() placeholder = '';
  @Input() required = false;
  @Input() validator: (string) => {[code: string]: {message: string}};
  @Input() value = '';

  @Output() valueChange = new EventEmitter<string>();

  control: FormControl;

  ngOnInit(): void {
    const validators = [];

    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.validator) {
      validators.push(this.validator);
    }

    this.control = new FormControl(this.value, validators);
    this.control.valueChanges.subscribe(this.valueChange);
  }

  ngOnChanges(): void {
    if (this.control && this.value !== this.control.value) {
      this.control.setValue(this.value);
    }
  }

  get errorMessage(): string {
    if (this.control.errors !== null) {
      if (this.control.hasError('required')) {
        return 'This field is required.';
      }
      return get(Object.values(this.control.errors), '0.message');
    }
    return '';
  }
}
