<header>
  <h1 class="font-page-header">Advanced Search</h1>
</header>

<div class="page-container" [ngClass]="{'manual-mode': queryForm.disabled}">
  <div data-id="search-query-editor" class="query-pane">
    <flyql-editor [(value)]="queryString" (valueChange)="enterManualMode()" placeholder="Build your query using the visual editor or type a query here"></flyql-editor>

    <div class="query-buttons">
      <button data-id="search-query-editor-run-button" type="button" mat-raised-button color="primary" (click)="runQuery()" [disabled]="!queryString">Run Query</button>
      <button data-id="search-query-editor-save-button" type="button" mat-raised-button color="primary" (click)="saveQuery()" [disabled]="!queryString">Save Query</button>
      <button data-id="search-query-editor-visual-button" type="button" mat-raised-button color="primary" (click)="exitManualMode()" [disabled]="!queryForm.disabled">Visual Mode</button>
    </div>

    <div class="margin-20"></div>

    <saved-queries (loadQuery)="loadSavedQuery($event)"></saved-queries>
  </div>

  <form [formGroup]="queryForm" class="visual-form">
    <div class="visual-actions">
      <mat-button-toggle-group [attr.data-id]="'search-query-and-or-toggle'" formControlName="outerConnective" class="outer-connective"
        matTooltip="This is the logical connective between term groups within the FlyQL query." matTooltipPosition="right">
        <mat-button-toggle [attr.data-id]="'search-query-and'" value="and">AND</mat-button-toggle>
        <mat-button-toggle [attr.data-id]="'search-query-or'" value="or">OR</mat-button-toggle>
      </mat-button-toggle-group>
      <div class="flex-space"></div>
      <button data-id="search-query-reset-button" type="button" mat-raised-button color="primary" (click)="confirmReset()" [disabled]="queryForm.disabled">Reset Query</button>
      <button data-id="search-query-add-term-group-button" type="button" mat-raised-button color="primary" (click)="addGroup()" [disabled]="queryForm.disabled" class="add-group-button">Add Term Group</button>
    </div>

    <!-- Shared autocomplete for fields -->
    <mat-autocomplete [attr.data-id]="'search-query-field-autocomplete'" #autoField="matAutocomplete" panelWidth="auto">
      <mat-option *ngFor="let field of fields$ | async" [value]="field.name">
        {{ field.name }}
      </mat-option>
    </mat-autocomplete>
    <!-- Shared autocomplete for values -->
    <mat-autocomplete [attr.data-id]="'search-query-display-autocomplete'" #autoValue="matAutocomplete" (optionSelected)="selectChip($event)" panelWidth="auto" >
      <mat-option *ngFor="let suggestion of suggestions$ | async" [value]="suggestion.value">
        {{ suggestion.display }}
      </mat-option>
    </mat-autocomplete>

    <ng-container formArrayName="queryGroups">
      <div *ngFor="let group of queryForm.get('queryGroups')['controls']; index as groupIndex" [formGroupName]="groupIndex" class="group mat-elevation-z2">
        <!-- Connective -->
        <mat-button-toggle-group [attr.data-id]="'search-query-connective-logic-toggle'" formControlName="connective" matTooltip="This is the logical connective between terms within this group." matTooltipPosition="right">
          <mat-button-toggle [attr.data-id]="'search-query-connective-logic-and'" value="and">AND</mat-button-toggle>
          <mat-button-toggle [attr.data-id]="'search-query-connective-logic-or'" value="or">OR</mat-button-toggle>
        </mat-button-toggle-group>

        <mat-list formArrayName="rules" role="list">
          <mat-list-item *ngFor="let rule of group.get('rules')['controls']; index as ruleIndex" [formGroupName]="ruleIndex" role="listitem">

            <!-- Field -->
            <mat-form-field [attr.data-id]="'search-query-field-input'" appearance="outline" class="inline-field medium-field">
              <input #field type="text" matInput formControlName="field"
                placeholder="Field"
                [matAutocomplete]="autoField"
                (focus)="bindFieldAutocomplete(rule)">
            </mat-form-field>

            <!-- Operator -->
            <mat-form-field [attr.data-id]="'search-query-operator-input'" appearance="outline" class="inline-field medium-field">
              <mat-select #operator formControlName="operator" placeholder="Operator">
                <mat-option *ngFor="let option of getOperatorOptions(rule)" [value]="option.value">{{ option.label || option.value }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Value -->
            <div [ngSwitch]="getValueInterface(rule)" class="inline-field grow-field">
              <!-- Chips -->
              <mat-form-field *ngSwitchCase="'chips'" appearance="outline" class="chip-field">
                <mat-chip-list #chipList formControlName="chips" aria-label="Query field value">
                  <mat-chip *ngFor="let value of rule.get('chips').value; index as chipIndex" class="chip"
                    [disabled]="queryForm.disabled"
                    (removed)="removeChip(rule, chipIndex)">
                    {{value}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    data-id="search-query-chips"
                    #chipInput
                    formControlName="text"
                    placeholder="Value(s)"
                    (focus)="bindSuggestionAutocomplete(rule)"
                    [matAutocomplete]="autoValue"
                    [matChipInputFor]="chipList"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addChip(rule, $event)">
                </mat-chip-list>
              </mat-form-field>

              <!-- Boolean -->
              <mat-form-field [attr.data-id]="'search-query-boolean-select'" *ngSwitchCase="'boolean'" appearance="outline">
                <mat-select formControlName="boolean">
                  <mat-option [attr.data-id]="'search-query-boolean-true'" [value]="true">True</mat-option>
                  <mat-option [attr.data-id]="'search-query-boolean-false'" [value]="false">False</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Range -->
              <ng-container *ngSwitchCase="'range'" formGroupName="number">
                <mat-form-field [attr.data-id]="'search-query-number-min-input'"  appearance="outline" class="inline-field short-field">
                  <input type="number" matInput formControlName="min" placeholder="Min">
                </mat-form-field>
                <span class="inline-field">&#8211;</span>
                <mat-form-field [attr.data-id]="'search-query-number-max-input'" appearance="outline" class="short-field">
                  <input type="number" matInput formControlName="max" placeholder="Max">
                </mat-form-field>
              </ng-container>

              <!-- Date -->
              <ng-container *ngSwitchCase="'date'" formGroupName="date">
                <mat-form-field [attr.data-id]="'search-query-date-min-input'" appearance="outline" class="inline-field short-field">
                  <input type="string" matInput formControlName="min" [matDatepicker]="minPicker" placeholder="Start" class="datepicker-input">
                  <mat-datepicker-toggle matSuffix [for]="minPicker" class="datepicker-toggle"></mat-datepicker-toggle>
                  <mat-datepicker #minPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field [attr.data-id]="'search-query-date-max-input'" appearance="outline" class="short-field">
                  <input type="string" matInput formControlName="max" [matDatepicker]="maxPicker" placeholder="End" class="datepicker-input">
                  <mat-datepicker-toggle matSuffix [for]="maxPicker" class="datepicker-toggle"></mat-datepicker-toggle>
                  <mat-datepicker #maxPicker></mat-datepicker>
                </mat-form-field>
              </ng-container>

              <!-- Input -->
              <mat-form-field [attr.data-id]="'search-query-value-autocomplete'" *ngSwitchDefault appearance="outline">
                <input type="string" matInput formControlName="text" placeholder="Value"
                  [matAutocomplete]="autoValue"
                  (focus)="bindSuggestionAutocomplete(rule)">
              </mat-form-field>
            </div>

            <button data-id="search-query-clear-button" type="button" mat-raised-button color="primary" (click)="resetRule(rule)" [disabled]="queryForm.disabled" class="inline-field">Clear</button>
            <button data-id="search-query-remove-button" *ngIf="canRemoveRule" type="button" mat-raised-button color="primary" (click)="removeRule(groupIndex, ruleIndex)" [disabled]="queryForm.disabled">Remove</button>
          </mat-list-item>
        </mat-list>

        <button data-id="search-query-add-term-button" type="button" mat-raised-button color="primary" (click)="addRule(group)" [disabled]="queryForm.disabled" class="add-rule-button">Add Term</button>
      </div>
    </ng-container>
  </form>
</div>
