import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { BehaviorSubject, Observable, combineLatest, defer } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import APP_MODULE from '../app.module.ajs';
import { FlywheelService } from '../flywheel.service';
import { loadSettings, saveSettings } from './setting.utils';
import { CachingService } from '../shared/caching.service';
import { getRoleMatrix } from '../shared/container.utils';
import { RoleMatrix } from '../shared/enums/container-access.enum';
import { RequestState } from '../shared/enums/request-state.enum';
import { SiteRole } from '../shared/enums/site-role.enum';
import { Permission } from '../shared/models/permission.model';
import { Profile } from '../shared/models/profile.model';
import { User } from '../shared/models/user.model';
import { UserSettings, defaultSessionSettings } from '../shared/models/user-settings.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends CachingService {
  private profile = {
    state: RequestState.Empty,
    subject: new BehaviorSubject<Profile>(undefined),
    request: this.flywheel.users.self().pipe(map(user => user as Profile)),
  }
  readonly profile$ = this.profile.subject.asObservable()

  private settings = new BehaviorSubject<UserSettings>(loadSettings())
  readonly settings$ = this.settings.asObservable()

  // Emits true if the user is an admin with "showAllData" enabled
  readonly exhaustiveMode$: Observable<boolean> = combineLatest(
    defer(() => this.getProfile()).pipe(filter(data => Boolean(data))),
    this.settings$,
  ).pipe(map(([ profile, settings ]) => SiteRole.isSiteAdmin(profile) && settings.showAllData))

  constructor(private flywheel: FlywheelService) {
    super();
  }

  private updateSettings(updates: Partial<UserSettings>): void {
    const settings = { ...this.settings.value, ...updates };
    this.settings.next(settings);
    saveSettings(settings);
  }

  set showAllData(value: boolean) {
    this.updateSettings({ showAllData: value });
  }

  getProfile({ refresh = false } = {}): Observable<Profile> {
    return this.getCacheableResource(this.profile, this.profile$, { refresh });
  }

  getRoleMatrix(container: { permissions?: Permission[] }): RoleMatrix {
    const profile = this.profile.subject.value;
    if (!profile) {
      throw new Error('User profile has not been loaded yet');
    }
    return getRoleMatrix(container, profile);
  }

  generateApiKey(): Observable<string> {
    return this.flywheel.users.generateKey().pipe(
      map(({ key }) => key)
    );
  }

  logout(): void {
    this.profile.state = RequestState.Empty;
    this.profile.subject.next(undefined);
    this.updateSettings(defaultSessionSettings);
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'profileService',
    downgradeInjectable(ProfileService)
  );

export function getAvatarColor(user: User): string {
  return intToRGB(hashCode(user._id));
}

function intToRGB(i: number): string {
  var c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
}

function hashCode(str: string): number {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}
