
    <div class="card no-padding">
      <h1 class="font-card-header has-button">
        <a (click)="open = !open" class="session-label">{{ session.label }}</a>
        <i *ngIf="writable" class="fa fa-ellipsis-v task-menu-btn pull-right" [matMenuTriggerFor]="appMenu"></i>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="createAcquisition(session)">Create Acquisition</button>
          <button mat-menu-item (click)="deleteSession(session)">Delete Session</button>
        </mat-menu>
      </h1>
      <div [hidden]="!open">
        <ng-content></ng-content>
      </div>
    </div>
  