import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ReplaySubject, Observable } from 'rxjs';

import { ApiConfig } from '../ajs-upgraded-providers';
import APP_MODULE from '../app.module.ajs';
import { CachingService } from './caching.service';
import { RequestState } from './enums/request-state.enum';
import { FlywheelService } from '../flywheel.service';
import { SiteSettings } from './models/site-settings.model';
import { Version } from './models/version.model';
import { queryStringToParams } from './url.utils';
import { shareReplay } from 'rxjs/operators';

interface Features {
  [name: string]: boolean | string
}

/**
 * These are features that don't require explicit configuration in order to be enabled.
 */
const standardFeatures: Features = {
  'subjects_view': true,
  'ohif_slice_order': 'alpha',
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends CachingService {
  public features: Features

  private version = {
    state: RequestState.Empty,
    subject: new ReplaySubject<Version>(1),
    request: this.flywheel.version(),
  }
  readonly version$ = this.version.subject.asObservable()

  private siteSettings = {
    state: RequestState.Empty,
    subject: new ReplaySubject<SiteSettings>(1),
    request: this.flywheel.siteSettings.get(),
  }
  readonly siteSettings$ = this.siteSettings.subject.asObservable()

  constructor(private apiConfig: ApiConfig, private flywheel: FlywheelService) {
    super();
    // Standard Features <- API Config <- Query Parameters
    this.features = Object.assign({}, standardFeatures, apiConfig.features, queryStringToParams(window.location.search, coerceBoolean));
  }

  getFeatureSetting(name: string): boolean | string {
    return this.features[name];
  }

  isFeatureEnabled(name: string): boolean {
    return Boolean(this.getFeatureSetting(name));
  }

  getVersion({ refresh = false } = {}): Observable<Version> {
    return this.getCacheableResource(this.version, this.version$, { refresh });
  }

  getSiteSettings({ refresh = false } = {}): Observable<SiteSettings> {
    return this.getCacheableResource(this.siteSettings, this.siteSettings$, { refresh });
  }

  setSiteSettings(body: Partial<SiteSettings>): Observable<null> {
    const observable = this.flywheel.siteSettings.put(body).pipe(
      shareReplay(1)
    );
    observable.subscribe(() => {
      this.getSiteSettings({ refresh: true });
    }, () => {});
    return observable;
  }
}

function coerceBoolean(value = ''): boolean | string {
  switch (value.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return value;
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'configService',
    downgradeInjectable(ConfigService)
  );
