
    <mat-form-field appearance="outline">
      <mat-select 
        [formControl]="control"
        [multiple]="multiple"
        [placeholder]="placeholder"
        [required]="required">
        <mat-option 
          *ngFor="let option of options" 
          [value]="option.value">
          {{ option.label || option.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        This field is required.
      </mat-error>
    </mat-form-field>
  