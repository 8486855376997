/**
 * Extend from Error and re-establish the prototype chain.
 * New error types can be defined by extending this class.
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
 */
class BaseError extends Error {
  constructor(message?: string) {
    super(message);
    if (typeof Object.setPrototypeOf === 'function') {
      Object.setPrototypeOf(this, new.target.prototype);
    } else {
      (this as any).__proto__ = new.target.prototype; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  }
}

export class DuplicateGroupError extends BaseError { }
