<h1 mat-dialog-title>Create Training Set</h1>
<div mat-dialog-content class="modal-wide">
  <form [formGroup]="form" class="form-full-width">
    <div data-id="training-set-files">
      <label class="modal-body-heading">{{ payload.files ? 'File Set' : 'Query Result' }}</label>
      <p>{{ payload.file_count }} file{{ payload.file_count === 1 ? '' : 's' }} selected.</p>
    </div>
    <div class="margin-20"></div>

    <div>
      <label class="modal-body-heading">Destination Project</label>
      <mat-form-field appearance="outline">
        <mat-select [attr.data-id]="'training-set-project-select'" formControlName="projectId">
          <mat-optgroup *ngFor="let group of groups$ | async" [label]="group.label">
            <mat-option *ngFor="let project of group.projects" [value]="project._id">
              {{ project.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error data-id="training-set-projects-error">
          A destination project is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="margin-20"></div>

    <div>
      <label class="modal-body-heading">Training Labels</label>
      <mat-form-field data-id="training-set-labels" appearance="outline">
        <mat-autocomplete #labelAutocomplete="matAutocomplete" panelWidth="auto"
          (optionSelected)="labelValueStream$.next($event.option.value)">
          <mat-option *ngFor="let field of fields$ | async" [value]="field.name">
            {{ field.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-chip-list #labelChipList formControlName="labels" aria-label="Training labels">
          <mat-chip *ngFor="let chipValue of form.get('labels').value; index as chipIndex"
            (removed)="removeChip(chipIndex)">
            {{ chipValue }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            #labelChipInput
            formControlName="labelInput"
            placeholder="Label"
            [matAutocomplete]="labelAutocomplete"
            [matChipInputFor]="labelChipList"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="labelValueStream$.next($event.value)">
        </mat-chip-list>
        <mat-error data-id="training-set-labels-error">
          At least one label is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="margin-20"></div>

    <div>
      <label class="modal-body-heading">Training File Name</label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="filename" data-id="training-set-filename">
      </mat-form-field>
      <small class="help-block">
        <span *ngIf="outputFilePath$ | async as filePath" data-id="training-set-output">
          The training set will be saved to <strong>{{ filePath }}</strong>
        </span>
      </small>
    </div>
    <div class="margin-20"></div>

  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onClose()" data-id="training-set-close">Close</button>
  <button mat-raised-button [disabled]="form.disabled || !form.valid"
    (click)="onCreate()" color="primary" data-id="training-set-create">Create</button>
</div>
