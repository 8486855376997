import takeWhile from 'lodash/takeWhile';

import { ContainerAccess, RoleMatrix } from './enums/container-access.enum';
import { SiteRole } from './enums/site-role.enum';
import { Group } from './models/group.model';
import { Permission } from './models/permission.model';
import { User } from './models/user.model';

export function getContainerType(container: { [key: string]: string } = {}): string {
  if (container.session) {
    return 'acquisition';
  }
  if (container.subject) {
    return 'session';
  }
  if (container.project) {
    return 'subject';
  }
  if (container.group) {
    return 'project';
  }
  if (container.curator) {
    return 'collection';
  }
  if (container.job) {
    return 'analysis';
  }
  throw new Error('Cannot determine container type.');
}

export function pluralize(containerType: string): string {
  if (containerType === 'analysis') {
    return 'analyses';
  }
  return containerType + 's';
}

export function isLabEdition(group: Group): boolean {
  return Boolean(group.is_lab);
}

export function getRoleMatrix(container: { permissions?: Permission[] }, user: User): RoleMatrix {
  const maxAccess = SiteRole.isSiteAdmin(user)
    ? ContainerAccess.valueOf(ContainerAccess.Admin)
    : Math.max(0,
      ...(container.permissions || [])
        .filter(({ _id }) => _id === user._id)
        .map(({ access }) => ContainerAccess.valueOf(access)));
  return ContainerAccess.roleMatrix(maxAccess);
}

interface RoutingSegments {
  group?: string
  project?: string
  subject?: string
  session?: string
}

export function buildRoutingString({ group, project, subject, session }: RoutingSegments): string {
  const segments = takeWhile([ group, project, subject, session ], Boolean);
  return `fw://${segments.join('/')}`;
}

/**
 * Recursive helper function to flatten the subject info fields.
 * For now they are being entered and displayed using dot notation.
 * Example: { a: { b: {c: 'test' } } } becomes { 'a.b.c = 'test' }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function flattenInfoKeys(target: Record<string, any>, prefix: string): Record<string, any> {
  const propName = prefix ? prefix + '.' :  '';
  const flattenedObj = {};
  for (const attr in target) {
    if (target[attr] instanceof Array) {
      flattenedObj[attr] = target[attr].join(',');
    } else if (typeof target[attr] === 'object') {
      Object.assign(flattenedObj, flattenInfoKeys(target[attr], propName + attr));
    } else {
      flattenedObj[propName + attr] = target[attr];
    }
  }
  return flattenedObj;
}
