import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DownloadSummary, DownloadNode } from '../../shared/models/download.model';
import { FlywheelService } from '../../flywheel.service';
import { NotificationService } from '../../ajs-upgraded-providers';
import { downloadFile } from '../../shared/browser.utils';


import { Observable } from 'rxjs';

@Component({
  selector: 'project-download',
  styles: [`
    :host {
      display: block;
      position: relative;
      padding-top: 10px;
    }
    .close-button {
      color: #ccc;
      position: absolute;
      right: 0;
      top: 0;
    }
    .footer-stats {
      align-items: flex-start;
      display: flex;
      flex-grow: 2;
    }
    .help-icon {
      color: #ccc;
      font-size: 15px;
      padding: 7px;
    }
    [mat-dialog-actions] {
      padding-bottom: 20px;
    }
    [mat-dialog-content] {
      border-top: 1px solid #ccc;
      padding: 30px 24px !important;
    }
    .small-chip {
      background: #ccc;
      border-radius: 20px;
      font-weight: 300;
      margin-right: 5px;
      padding: 5px 15px;
    }
  `],
  templateUrl: './project-download.html',
})

export class ProjectDownloadComponent {

  public allTypes = true
  public downloadList: string[] = []
  public downloadNode: DownloadNode[]
  public fullProjectDownload = true  
  public include = {
    analyses: true,
    metadata: true,
  }
  public summary$: Observable<DownloadSummary>
  public total = {
    files: 0,
    size: 0,
  }
  public types: {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { projectId: string },
    private dialogRef: MatDialogRef<ProjectDownloadComponent>,
    private flywheel: FlywheelService,
    private notification: NotificationService,
  ) {
    this.downloadNode = [{ level: 'project', _id: data.projectId }];
    this.flywheel.downloads.summary(this.downloadNode).subscribe(
      summary => {
        this.types = summary;
        this.calculateTotals({ value: Object.values(this.types) });
      }
    );
  }

  /**
   * @description - calculates total number of files 
   *  and size for footer display
   * @param event - files to be included in totals calculation
   *  array is wrapped up in an object and assigned to a value key
   *  to match the structure returned by $event change on mat select
   */
  calculateTotals(event: { value: FileType[] }): void {
    // reset before each calculation
    this.total.files = 0;
    this.total.size = 0;

    if (event.value.length === Object.keys(this.types).length && !this.allTypes) {
      // all of the types are selected, set indication.
      this.allTypes = true;
    } else {
      this.downloadList = [];
    }

    // calculate totals based on the currently selected types
    event.value.forEach(type => {
      this.total.files = this.total.files + type.count;
      this.total.size = this.total.size + type.mb_total;
      this.downloadList.push(String(type._id));
    });
  }

  /**
   * @description - builds up structure necessary for download
   *  then triggers download once the structure has been built
   */
  download(): void {
    const body = {
      filters: [{ types: { '+': this.downloadList } }],
      nodes: this.downloadNode,
      optional: true,
    };
    const downloadParams = {
      params: {
        analyses: this.include.analyses,
        metadata: this.include.metadata,
        type: '',
      }
    };
    this.fullProjectDownload ? downloadParams.params.type = 'full' : downloadParams.params.type = 'classic';
    // obtain ticket for download
    this.flywheel.downloads.createTicket(body, downloadParams).subscribe({
      next: (summary) => {
        // actually download the file
        downloadFile(summary.filename, '/api/download?ticket='+summary.ticket);
        this.dialogRef.close();
      },
      error: () => {
        this.notification.error('download.project.error');
      },
    });
  }

  /**
   * @description - toggle the state of selecting
   *  all file types totals will also be recalculated
   */
  toggleAllFiles(): void {
    this.allTypes = !this.allTypes;
    this.allTypes ? this.calculateTotals({ value: Object.values(this.types) }) : this.calculateTotals({ value: [] });
  }

  /**
   * @description - toggles the include object based on value context
   * @param value - 'analyses' or 'metadata', the value being toggled
   */
  toggleInclude(value: string): void {
    this.include[value] = !this.include[value];
  }

  /**
   * @description - toggle the state of wanting a full project download
   *  update the inclusion of analyses and metadata as well
   */
  toggleFullProjectDownload(): void {
    this.fullProjectDownload = !this.fullProjectDownload;
    this.include = {
      analyses: this.fullProjectDownload,
      metadata: this.fullProjectDownload,
    };
  }
}

export interface FileType {
  _id: string
  count: number
  mb_total: number
}
