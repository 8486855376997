
    <h1 mat-dialog-title>Save Query</h1>
    <div mat-dialog-content>
      <form>
        <label class="field">
          <span class="mat-body-strong">Label</span>
          <mat-form-field appearance="outline">
            <input data-id="save-query-label-input" matInput name="label" [(ngModel)]="label" required>
            <mat-error>Label is required</mat-error>
          </mat-form-field>
        </label>
        <pre class="query">{{ query.structured_query }}</pre>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <button data-id="save-query-cancel-button" mat-button (click)="onCancel()">Cancel</button>
      <button data-id="save-query-save-button" mat-raised-button (click)="onSave()" [disabled]="!this.label" color="primary" cdkFocusInitial>Save</button>
    </div>
  