import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import omit from 'lodash/omit';
import { Observable, Subject as RxSubject, BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

import APP_MODULE from '../app.module.ajs';
import { FlywheelService, ApiParams } from '../flywheel.service';
import { ProfileService } from '../profile/profile.service';
import { CachingService } from '../shared/caching.service';
import { RequestState } from '../shared/enums/request-state.enum';
import { Paginated } from '../shared/models/paginated.model';
import { Session } from '../shared/models/session.model';
import { Subject } from '../shared/models/subject.model';
import { TreeQuery } from '../shared/models/tree-query.model';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends CachingService {
  private sessions = {
    state: RequestState.Empty,
    subject: new RxSubject<Paginated<Session[]>>(),
  }
  readonly sessions$ = this.sessions.subject.asObservable()

  private subjects = {
    state: RequestState.Empty,
    subject: new RxSubject<Paginated<Subject[]>>(),
  }
  readonly subjects$ = this.subjects.subject.asObservable()

  readonly checkedSessions$ = new BehaviorSubject<Map<string, SessionCheckedStatus>>(new Map())

  constructor(private flywheel: FlywheelService, private profileService: ProfileService) {
    super();
  }

  getSessionPage(params: ApiParams): Observable<Paginated<Session[]>> {
    this.profileService.exhaustiveMode$.pipe(first()).subscribe(exhaustive => {
      const queryParams = omit(params, ['projectId', 'groupId', 'collectionId']);
      if (exhaustive) {
        queryParams.exhaustive = true;
      }

      const filters = params.filter ? params.filter.split(',') : [];
      if (params.projectId) {
        filters.push(`project=${params.projectId}`);
      } else if (params.groupId) {
        filters.push(`group=${params.groupId}`);
      }
      if (filters.length > 0) {
        queryParams.filter = filters.join(',');
      }

      let query$ = this.flywheel.sessions.query(queryParams);
      if (params.collectionId) {
        query$ = this.flywheel.collections.sessions(params.collectionId as string, queryParams);
      }

      this.sessions.state = RequestState.Fetching;
      query$.subscribe({
        next: sessions => {
          this.sessions.state = RequestState.Loaded;
          this.sessions.subject.next(sessions);
        },
        error: error => {
          this.sessions.state = RequestState.Loaded;
          this.error.next(error);
        },
      });
    })
    return this.sessions$;
  }

  getSession(sessionId: string): Observable<Session> {
    return this.flywheel.sessions.get(sessionId);
  }

  getSubjectPage(body: TreeQuery, params: ApiParams): Observable<Paginated<Subject[]>> {
    this.profileService.exhaustiveMode$.pipe(first()).subscribe(exhaustive => {
      const queryParams = omit(params, ['projectId']);
      if (exhaustive) {
        queryParams.exhaustive = true;
      }
      const filters = params.filter ? params.filter.split(',') : [];
      if (params.projectId) {
        queryParams.filter = [...filters, `project=${params.projectId}`].join(',');
      }
      this.subjects.state = RequestState.Fetching;
      this.flywheel.tree.query<Subject>(body, queryParams).subscribe({
        next: subjects => {
          this.subjects.state = RequestState.Loaded;
          this.subjects.subject.next(subjects);
        },
        error: error => {
          this.subjects.state = RequestState.Loaded;
          this.error.next(error);
        },
      });
    });
    return this.subjects$;
  }

  getCheckedSessions(): Map<string, SessionCheckedStatus> {
    return this.checkedSessions$.value;
  }

  clearCheckedSessions(): void {
    this.checkedSessions$.next(new Map());
  }

  checkSessions(sessionIds: string[], checked?: boolean, { clearAcquisitions = false } = {}): void {
    const checkedSessions = this.getCheckedSessions();
    for (const sessionId of sessionIds) {
      const status = checkedSessions.get(sessionId) || {
        _id: sessionId,
        checked: false,
        indeterminate: false,
        acquisitions: new Set(),
      };
      checked = typeof checked === 'boolean' ? checked : !status.checked;
      if (clearAcquisitions || status.checked !== checked) {
        // if we transition, clear individual acquisition selections
        status.acquisitions.clear();
        status.indeterminate = false;
      }
      status.checked = checked;
      checkedSessions.set(sessionId, status);
    }
    this.checkedSessions$.next(checkedSessions);
  }

  checkAcquisitions(session: Session, acquisitionIds: string[]): void {
    return this.checkAcquisitionsBulk([{ session, acquisitionIds }]);
  }

  checkAcquisitionsBulk(updates: AcquisitionUpdate[]): void {
    const checkedSessions = this.getCheckedSessions();
    for (const { session, acquisitionIds } of updates) {
      const status = checkedSessions.get(session._id) || {
        _id: session._id,
        checked: false,
        indeterminate: true, // indeterminate by default
        acquisitions: new Set(),
      };
      status.checked = acquisitionIds.length > 0;
      if (session.acquisitions instanceof Array) {
        status.indeterminate = status.checked && acquisitionIds.length < session.acquisitions.length;
      }
      status.acquisitions = new Set(acquisitionIds);
      checkedSessions.set(session._id, status);
    }
    this.checkedSessions$.next(checkedSessions);
  }
}

interface SessionCheckedStatus {
  _id: string
  checked: boolean
  indeterminate: boolean
  acquisitions: Set<string>
}

interface AcquisitionUpdate {
  session: Partial<Session>
  acquisitionIds: string[]
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'sessionService',
    downgradeInjectable(SessionService)
  );
