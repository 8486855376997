
    <div mat-dialog-content class="text-center">
      <span class="material-icons cloud-off-icon">cloud_off</span>
      <p class="dialog-title">Unsupported Browser Warning</p>
      <p>
        Flywheel is optimized for the most recent version of Google Chrome.  If you continue, you may experience degraded performance and loss of functionality.
      </p>
      <div class="dialog-buttons">
        <button mat-dialog-close type="button" mat-raised-button color="secondary">Continue Using Flywheel</button>
        <a href="https://www.google.com/chrome/browser/" target="_blank">
          <button type="button" mat-raised-button color="primary">Download Google Chrome</button>
        </a>
      </div>
    </div>
  