declare const angular: angular.IAngularStatic;

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { AdminService } from './admin.service';
import { AddDeviceComponent } from './devices/add-device.component';
import { DeviceSettingsComponent } from './devices/device-settings.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
  ],
  declarations: [
    AddDeviceComponent,
    DeviceSettingsComponent,
  ],
  entryComponents: [
    AddDeviceComponent,
    DeviceSettingsComponent,
  ],
  providers: [
    AdminService,
  ],
})
export class AdminModule { }

angular.module('admin', [])
  .factory(
    'adminService',
    downgradeInjectable(AdminService)
  )
  .directive(
    'deviceSettings',
    downgradeComponent({ component: DeviceSettingsComponent })
  );
