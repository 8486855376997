import { Injectable } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

import APP_MODULE from '../../app.module.ajs';
import { FlywheelService } from '../../flywheel.service';
import { getFileClassification, getOriginInfo } from '../../shared/file.utils';
import { Subject as FlywheelSubject } from '../../shared/models/subject.model';
import { Session } from '../../shared/models/session.model';
import { subjectTreeQuery } from './subject-tab.utils';

export interface SelectedSubject {
  subject: FlywheelSubject
  session: Session
}

@Injectable()
export class SubjectTabService {
  public selected$ = new BehaviorSubject<SelectedSubject>({
    subject: null,
    session: null,
  });
  public changes$ = new Subject<undefined>();

  constructor(private flywheel: FlywheelService) { }

  set selectedSubject(subject) {
    this.refetchSubject(subject, null);
  }

  set selectedSession(session) {
    if (session && session.subject) {
      this.flywheel.subjects.get(session.subject._id).subscribe(subject => {
        this.selected$.next({ subject: subject, session: session });
      });
    } else {
      this.selected$.next({ subject: null, session: session });
    }
  }

  refetchSubject(subject = this.selected$.value.subject, session = this.selected$.value.session): void {
    if (subject && subject._id) {
      /**
       * /api/tree has session data, but no custom info
       * /api/subjects has all the fields, but no sessions
       */
      combineLatest(
        this.flywheel.tree.query<FlywheelSubject>(subjectTreeQuery, {
          filter: `_id=${subject._id}`,
          join_avatars: true,
        }),
        this.flywheel.subjects.get(subject._id),
      ).subscribe(([ { results: [treeSubject] }, detailSubject ]) => {
        if (treeSubject) {
          // attach origin and classification information to files
          for (const session of treeSubject.sessions || []) {
            for (const acq of session.acquisitions || []) {
              for (const file of acq.files || []) {
                Object.assign(file, {
                  originInfo: getOriginInfo(file, acq['join-origin']),
                  modalityDisplay: getFileClassification(file),
                });
              }
            }
          }
        }
        this.selected$.next({
          subject: Object.assign({}, treeSubject, detailSubject),
          session: session,
        });
      });
    } else {
      this.selected$.next({
        subject: subject,
        session: session,
      });
    }
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'subjectTabService',
    downgradeInjectable(SubjectTabService)
  );
