import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatChipInputEvent, MatAutocompleteSelectedEvent, MatAutocomplete, MatDialog } from '@angular/material';
import { of, Observable } from 'rxjs';
import { startWith, switchMap, tap, debounceTime, map, filter } from 'rxjs/operators';

import { UIRouterState, NotificationService } from '../../ajs-upgraded-providers';
import { FlywheelService } from '../../flywheel.service';
import { ConfirmDialog } from '../../shared/dialogs/confirm-dialog.component';
import { SavedSearch } from '../../shared/models/search/saved-search.model';
import { SearchField } from '../../shared/models/search/search-field.model';
import { SearchSuggestion } from '../../shared/models/search/search-suggestions.model';
import { SidebarService } from '../../sidebar/sidebar.service';
import {
  buildQueryString,
  buildRuleString,
  getOperatorOptions,
  ruleFormGroup,
  SelectOption,
  VisualQuery,
} from './visual-query.utils';
import { SaveQueryComponent } from '../saved-queries/save-query.component';

type EditMode = 'visual' | 'manual';

@Component({
  selector: 'advanced-query',
  templateUrl: './advanced-query.component.html',
  styleUrls: ['./advanced-query.component.css'],
})
export class AdvancedQueryComponent implements OnInit {
  public queryForm: FormGroup
  public fields$: Observable<SearchField[]> = of([])
  public suggestions$: Observable<SearchSuggestion[]> = of([])
  public fieldCache = new Map<string, SearchField>()
  public queryString = ''
  public canRemoveRule = true
  public editMode: EditMode = 'visual'

  @ViewChild('autoValue') autoValue: MatAutocomplete

  private focusedRule: FormGroup = null

  constructor(
    private dialog: MatDialog,
    private flywheel: FlywheelService,
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private routerState: UIRouterState,
    private sidebarService: SidebarService,
  ) { }

  ngOnInit(): void {
    this.sidebarService.state = 'search';

    if (this.sidebarService.search.visualQuery) {
      this.queryForm = this.buildQueryForm(this.sidebarService.search.visualQuery);
      this.queryForm.markAsDirty();
    } else {
      this.queryForm = this.formBuilder.group({});
      this.resetQueryForm();
    }

    this.canRemoveRule = (this.queryForm.controls.queryGroups as FormArray).length > 1
      || (this.queryForm.get('queryGroups.0.rules') as FormArray).length > 1;

    const queryString = buildQueryString(this.buildVisualQuery());
    const savedQuery = this.sidebarService.search.structuredQuery;
    if (savedQuery && savedQuery !== queryString) {
      this.queryString = savedQuery;
      this.enterManualMode();
    } else {
      this.queryString = queryString;
    }

    this.queryForm.valueChanges.pipe(
      filter(() => this.editMode === 'visual'),
      debounceTime(500),
    ).subscribe(() => {
      this.queryString = buildQueryString(this.buildVisualQuery());
    });
  }

  confirmReset(): void {
    if (this.queryForm.dirty) {
      this.dialog.open(ConfirmDialog, {
        data: { content: 'Are you sure you want to reset this query?' }
      }).afterClosed().pipe(filter(Boolean)).subscribe(() => {
        this.resetQueryForm();
      });
    }
  }

  addGroup(): void {
    const groups = this.queryForm.controls.queryGroups as FormArray;
    groups.insert(0, this.formBuilder.group({
      connective: 'and',
      rules: this.formBuilder.array([ruleFormGroup()]),
    }));
    this.canRemoveRule = true;
  }

  addRule(group: FormGroup): void {
    const rules = group.controls.rules as FormArray;
    rules.push(ruleFormGroup());
    this.canRemoveRule = true;
  }

  resetRule(rule: FormGroup): void {
    rule.reset();
    rule.controls.text.setValue('');
    rule.controls.chips.setValue([]);
    rule.controls.boolean.setValue(true);
  }

  removeRule(groupIndex: number, ruleIndex: number): void {
    const groups = this.queryForm.controls.queryGroups as FormArray;
    const rules = groups.get([groupIndex, 'rules']) as FormArray;
    if (rules.length > 1) {
      rules.removeAt(ruleIndex);
    } else {
      groups.removeAt(groupIndex);
    }
    this.canRemoveRule = groups.length > 1
      || (groups.get([0, 'rules']) as FormArray).length > 1;
  }

  /**
   * Fetch field name suggestions for this rule and feed them into the autocomplete.
   */
  bindFieldAutocomplete(rule: FormGroup): void {
    const field = rule.controls.field;
    this.fields$ = field.valueChanges.pipe(
      startWith(field.value),
      filter(Boolean),
      switchMap(value => this.flywheel.dataexplorer.fields({ field: value })),
      tap(fields => {
        fields.forEach(field => this.fieldCache.set(field.name, field));
      }),
    );
  }

  getOperatorOptions(rule: FormGroup): SelectOption[] {
    const field = this.fieldCache.get(rule.controls.field.value);
    const options = getOperatorOptions(field ? field.type : 'string');
    if (!options.some(({ value }) => value === rule.controls.operator.value)) {
      rule.controls.operator.setValue(options[0].value);
    }
    return options;
  }

  getValueInterface(rule: FormGroup): string {
    const field = this.fieldCache.get(rule.controls.field.value) || { type: 'string' };
    const operator = rule.controls.operator.value || '';
    if (operator === 'exists' || field.type === 'boolean') {
      return 'boolean';
    }
    if (field.type === 'date' && operator === 'range') {
      return 'date';
    }
    if (operator === 'range') {
      return 'range';
    }
    if (operator.includes('contains')) {
      return 'input';
    }
    return 'chips';
  }

  /**
   * Fetch value suggestions for this rule and feed them into the autocomplete.
   */
  bindSuggestionAutocomplete(rule: FormGroup): void {
    this.focusedRule = rule; // bind rule for suggestion selection event

    const json = rule.getRawValue();
    const visualRule = {
      ...json,
      operator: 'is', // normalize value format
      field: this.fieldCache.get(json.field) || { name: json.field, type: 'string' },
    };
    this.suggestions$ = rule.controls.text.valueChanges.pipe(
      switchMap(text => {
        return this.flywheel.dataexplorer.suggest({ structured_query: buildRuleString({ ...visualRule, chips: [text] }) })
      }),
      map(({ suggestions }) => suggestions)
    );
  }

  /**
   * Select a chip from the autocomplete suggestions.
   */
  selectChip(event: MatAutocompleteSelectedEvent): void {
    if (!this.focusedRule) {
      return;
    }
    const chips = this.focusedRule.controls.chips;
    const value = [...chips.value, event.option.value];
    setTimeout(() => {
      // defer this to drop the partial value added by the input blur event
      chips.setValue(value);
    });
  }

  /**
   * Add a value chip via Enter key or blur
   */
  addChip(rule: FormGroup, event: MatChipInputEvent): void {
    const { input, value = '' } = event;
    if (input) {
      input.value = '';
    }
    if (this.autoValue.isOpen && document.activeElement !== input) {
      return; // if we've selected a suggestion, don't capture the partial input
    }
    if (value) {
      const chips = rule.get('chips');
      chips.setValue([...chips.value, value]);
    }
  }

  removeChip(rule: FormGroup, chipIndex: number): void {
    const chips = rule.get('chips');
    chips.setValue([...chips.value.slice(0, chipIndex), ...chips.value.slice(chipIndex + 1)]);
  }

  enterManualMode(): void {
    this.editMode = 'manual';
    this.queryForm.disable();
  }

  exitManualMode(): void {
    this.dialog.open(ConfirmDialog, {
      data: { content: 'Return to visual query mode? Your custom FlyQL changes will be lost.' }
    }).afterClosed().pipe(filter(Boolean)).subscribe(() => {
      this.editMode = 'visual';
      this.queryForm.enable();
    });
  }

  runQuery(): void {
    const visualQuery = this.buildVisualQuery();
    this.sidebarService.search.visualQuery = visualQuery;
    this.sidebarService.search.structuredQuery = this.editMode === 'visual' ? buildQueryString(visualQuery) : this.queryString;
    this.sidebarService.search.query = '';
    this.sidebarService.search.sidebarFilters = [];
    this.sidebarService.search.tableFilters = [];
    this.sidebarService.performSearch().subscribe(response => {
      // set initial facets
      this.sidebarService.search.facets = response.facets.facets;
      this.routerState.go('index.search', { advanced: true });
    }, () => {
      this.notification.error('dataexplorer.advancedQuery.error');
    });
  }

  saveQuery(): void {
    const search = {
      structured_query: this.editMode === 'visual' ? buildQueryString(this.buildVisualQuery()) : this.queryString,
    };
    this.dialog.open(SaveQueryComponent, { data: search }).afterClosed().pipe(filter(data => data)).subscribe(({ label }) => {
      this.sidebarService.saveSearch({
        label: label,
        search: search,
      })
    });
  }

  loadSavedQuery(search: SavedSearch): void {
    this.queryString = search.search.structured_query;
    this.enterManualMode();
  }

  /**
   * Build a JSON representation of the query form.
   */
  private buildVisualQuery(): VisualQuery {
    const json = this.queryForm.getRawValue();
    return {
      outerConnective: json.outerConnective,
      queryGroups: json.queryGroups.map(group => ({
        ...group,
        rules: group.rules.map(rule => ({
          ...rule,
          field: this.fieldCache.get(rule.field) || { name: rule.field, type: 'string' },
        })),
      })),
    };
  }

  /**
   * Build a query form from a JSON representation of the query.
   */
  private buildQueryForm(visualQuery: VisualQuery): FormGroup {
    return this.formBuilder.group({
      outerConnective: visualQuery.outerConnective,
      queryGroups: this.formBuilder.array(visualQuery.queryGroups.map(group =>
        this.formBuilder.group({
          connective: group.connective,
          rules: this.formBuilder.array(group.rules.map(rule => {
            this.fieldCache.set(rule.field.name, rule.field);
            return ruleFormGroup(rule);
          })),
        }),
      )),
    });
  }

  /**
   * Reset the form, preserving the top-level subscription.
   */
  private resetQueryForm(): void {
    this.queryForm.setControl('outerConnective', this.formBuilder.control('and'));
    this.queryForm.setControl('queryGroups', this.formBuilder.array([
      this.formBuilder.group({
        connective: 'and',
        rules: this.formBuilder.array([ ruleFormGroup() ]),
      }),
    ]));
    this.queryForm.markAsPristine();
  }
}
