import { Component, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { FlywheelService } from '../../flywheel.service';
import { ConfirmDialog } from '../../shared/dialogs/confirm-dialog.component';
import { SavedSearch } from '../../shared/models/search/saved-search.model';
import { SidebarService } from '../../sidebar/sidebar.service';

@Component({
  selector: 'saved-queries',
  template: `
    <div class="component-container">
      <h4 class="mat-h4">Saved Queries</h4>
      <div *ngIf="savedSearches$ | async; let savedSearches; else loading">
        <table mat-table *ngIf="savedSearches.length > 0; else empty" [dataSource]="savedSearches" class="fw-table data-table">
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef>Label</th>
            <td mat-cell *matCellDef="let element">{{ element.label }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="actions">
              <button data-id="saved-queries-publish-button" mat-icon-button (click)="onLoadQuery(element)" matTooltip="Load Query" matTooltipPosition="above">
                <mat-icon>publish</mat-icon>
              </button>
              <button data-id="saved-queries-delete-button" mat-icon-button (click)="onDeleteQuery(element)" matTooltip="Delete Query" matTooltipPosition="above">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="searchColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: searchColumns;"></tr>
        </table>
        <ng-template #empty>You don't have any queries saved.</ng-template>
      </div>
      <ng-template #loading>
        <div>Loading saved queries...</div>
      </ng-template>
    </div>
  `,
  styles: [`
    .actions {
      display: flex;
      justify-content: flex-end;
    }
    .component-container {
      margin: 16px;
    }
    .data-table {
      margin: 0;
      width: 100% !important;
    }
  `]
})
export class SavedQueriesComponent {
  @Output() loadQuery = new EventEmitter<SavedSearch>()

  public savedSearches$: Observable<SavedSearch[]> = of(null)
  public searchColumns = ['label', 'actions']

  constructor(
    private dialog: MatDialog,
    private flywheel: FlywheelService,
    private sidebarService: SidebarService,
  ) { }

  ngOnInit(): void {
    this.savedSearches$ = this.sidebarService.getSavedSearches();
  }

  onLoadQuery(listSearch: SavedSearch): void {
    this.flywheel.dataexplorer.queries.get(listSearch._id).subscribe(fullSearch => {
      this.loadQuery.emit(fullSearch);
    });
  }

  onDeleteQuery(search: SavedSearch): void {
    this.dialog.open(ConfirmDialog, {
      data: { content: 'Are you sure you want to delete this query?' }
    }).afterClosed().pipe(
      filter(Boolean),
      switchMap(() => this.flywheel.dataexplorer.queries.delete(search._id)),
    ).subscribe(() => {
      this.sidebarService.getSavedSearches({ refresh: true });
    });
  }
}
