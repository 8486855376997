import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { filter, switchMap, map, debounceTime } from 'rxjs/operators';

import { FlywheelService } from '../../flywheel.service';
import { SearchSuggestion } from '../../shared/models/search/search-suggestions.model';

type SuggestionOption = SearchSuggestion & { query: string };

@Component({
  selector: 'flyql-editor',
  template: `
    <div class="component-container">
      <mat-autocomplete #autoSuggest="matAutocomplete" autoActiveFirstOption
        (optionSelected)="restoreCursor()" panelWidth="auto">
        <mat-option *ngFor="let suggestion of suggestions$ | async" [value]="suggestion.query">
          {{ suggestion.display }}
        </mat-option>
      </mat-autocomplete>
      <mat-form-field appearance="outline" class="query-string">
        <textarea #queryTextarea matInput [formControl]="queryControl"
          [placeholder]="placeholder"
          [matAutocomplete]="autoSuggest" (keyup)="saveCursor()" (mouseup)="saveCursor()"></textarea>
      </mat-form-field>
    </div>
  `,
  styles: [`
    .component-container {
      margin: 20px 0;
    }
    .query-string {
      display: block;
      margin: 0 16px;
    }
    .query-string textarea {
      min-height: 50px;
    }
  `],
})
export class FlyqlEditorComponent implements OnInit, OnChanges {
  @Input() value = ''
  @Input() placeholder = ''
  @Input() disabled = false

  @Output() valueChange = new EventEmitter<string>()

  @ViewChild('queryTextarea') queryTextarea: ElementRef

  public queryControl = new FormControl('', [], this.validateQuerySyntax.bind(this))
  public suggestions$: Observable<SuggestionOption[]> = of([])

  private textAfterCursor = ''

  constructor(private flywheel: FlywheelService) { }

  ngOnInit(): void {
    this.queryControl.setValue(this.value);
    this.disabled ? this.queryControl.disable() : this.queryControl.enable();

    const valueChanges = this.queryControl.valueChanges.pipe(
      filter(value => value !== this.value),
    );
    valueChanges.subscribe(this.valueChange);

    this.suggestions$ = valueChanges.pipe(
      debounceTime(200),
      switchMap(value => {
        const textarea = this.queryTextarea.nativeElement;
        return this.flywheel.dataexplorer.suggest({
          // Send value up to the cursor to allow backtracked suggestions
          structured_query: value.slice(0, textarea.selectionStart),
        }).pipe(map(response => {
          // Format suggestion values into the integration of the suggestion
          // within the current query value.
          const before = value.slice(0, response.from);
          const after = value.slice(textarea.selectionEnd);
          return response.suggestions.map(suggestion => ({
            ...suggestion,
            query: before + suggestion.value + after,
          }));
        }));
      }),
    );
  }

  ngOnChanges(): void {
    if (this.value !== this.queryControl.value) {
      this.queryControl.setValue(this.value);
    }
    this.disabled ? this.queryControl.disable() : this.queryControl.enable();
  }

  saveCursor(): void {
    const textarea = this.queryTextarea.nativeElement;
    this.textAfterCursor = textarea.value.slice(textarea.selectionEnd);
  }

  /**
   * Once an autocomplete selection is made, the query value is replaced with
   * a new value and the cursor is moved to the end. This function fires afterward
   * and moves the cursor back to a natural location based on the remaining text
   * within the query.
   */
  restoreCursor(): void {
    const textarea = this.queryTextarea.nativeElement;
    const cursorIndex = textarea.value.lastIndexOf(this.textAfterCursor);
    textarea.selectionStart = cursorIndex;
    textarea.selectionEnd = cursorIndex;
  }

  validateQuerySyntax(control: AbstractControl): Observable<ValidationErrors> {
    if (!control.value) {
      return of(null);
    }
    // Delay this to reduce request volume
    return timer(500).pipe(
      switchMap(() => this.flywheel.dataexplorer.parse({ structured_query: control.value })),
      map(response => {
        if (response.valid) {
          return null;
        }
        return { queryValid: response.errors };
      }),
    );
  }
}
