import { Component, Inject } from '@angular/core'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { MAT_DIALOG_DATA } from '@angular/material'; // eslint-disable-line @typescript-eslint/no-unused-vars

@Component({
  selector: 'confirm-dialog',
  template: `
    <h1 mat-dialog-title *ngIf="title">{{title}}</h1>
    <div mat-dialog-content>{{content}}</div>
    <div mat-dialog-actions align="center">
      <button data-id="modal-no-button" mat-button mat-dialog-close>No</button>
      <button data-id="modal-yes-button" mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
    </div>
  `,
})
export class ConfirmDialog {
  public title: string
  public content: string = 'Are you sure?'
  constructor(
    @Inject(MAT_DIALOG_DATA) data: { title?: string, content?: string } = {},
  ) {
    this.title = data.title;
    this.content = data.content;
  }
}
