import { formatDate as ngFormatDate } from '@angular/common';

import { defaultLocale } from '../app.constants';

export function formatDate(value: string | number | Date, format: string): string {
  return ngFormatDate(value, format, defaultLocale);
}

// Format a date for our API
export function formatDateStamp(value: string | number | Date): string {
  return formatDate(value, 'yyyy-MM-dd');
}
