
    <mat-form-field appearance="outline" class="form-field">
      <mat-chip-list #chipList [attr.aria-label]="placeholder" class="mat-chip-list-stacked">
        <mat-chip *ngFor="let chipValue of value; index as chipIndex"
          (removed)="removeChip(chipIndex)"
          class="chip">
          <span class="chip-label">{{ getLabelFor(chipValue) }}</span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          #chipInput
          [formControl]="typeaheadInput"
          [placeholder]="value.length > 0 ? '' : placeholder"
          [matAutocomplete]="autoOptions"
          [matChipInputFor]="chipList"
          class="chip-input">
      </mat-chip-list>
    </mat-form-field>
    <mat-autocomplete #autoOptions="matAutocomplete" (optionSelected)="selectChip($event)" panelWidth="auto">
      <mat-option *ngFor="let option of typeaheadOptions$ | async" [value]="option.value">
        {{ option.label }} 
        <span *ngIf="option.email" class="text-muted user-email">{{ option.email }}</span>
      </mat-option>
    </mat-autocomplete>
  