import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ReplaySubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import APP_MODULE from '../app.module.ajs';
import { FlywheelService, ApiParams } from '../flywheel.service';
import { ProfileService } from '../profile/profile.service';
import { ProjectModule } from './project.module';
import { CachingService } from '../shared/caching.service';
import { RequestState } from '../shared/enums/request-state.enum';
import { Group } from '../shared/models/group.model';
import { Project } from '../shared/models/project.model';

@Injectable({
  providedIn: ProjectModule,
})
export class ProjectService extends CachingService {
  private projects = {
    state: RequestState.Empty,
    subject: new ReplaySubject<Project[]>(1),
    request: this.profileService.exhaustiveMode$.pipe(
      switchMap(exhaustive => this.flywheel.projects.query(exhaustive ? { exhaustive: true } : undefined)),
    ),
  }
  readonly projects$ = this.projects.subject.asObservable()

  private groups = {
    state: RequestState.Empty,
    subject: new ReplaySubject<Group[]>(1),
    request: this.profileService.exhaustiveMode$.pipe(
      switchMap(exhaustive => this.flywheel.groups.query(exhaustive ? { exhaustive: true } : undefined))
    ),
  }
  readonly groups$ = this.groups.subject.asObservable()

  constructor(private flywheel: FlywheelService, private profileService: ProfileService) {
    super();
  }

  getProjects({ refresh = false } = {}): Observable<Project[]> {
    return this.getCacheableResource(this.projects, this.projects$, { refresh });
  }

  getGroups({ refresh = false } = {}): Observable<Group[]> {
    return this.getCacheableResource(this.groups, this.groups$, { refresh });
  }

  getProject(projectId: string): Observable<Project> {
    return this.flywheel.projects.get(projectId);
  }

  projectQuery(params: ApiParams = {}): Observable<Project[]> {
    return this.flywheel.projects.query(params);
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'projectService',
    downgradeInjectable(ProjectService)
  );
