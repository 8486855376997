import { Component } from '@angular/core';

@Component({
  selector: 'browser-warning',
  template: `
    <div mat-dialog-content class="text-center">
      <span class="material-icons cloud-off-icon">cloud_off</span>
      <p class="dialog-title">Unsupported Browser Warning</p>
      <p>
        Flywheel is optimized for the most recent version of Google Chrome.  If you continue, you may experience degraded performance and loss of functionality.
      </p>
      <div class="dialog-buttons">
        <button mat-dialog-close type="button" mat-raised-button color="secondary">Continue Using Flywheel</button>
        <a href="https://www.google.com/chrome/browser/" target="_blank">
          <button type="button" mat-raised-button color="primary">Download Google Chrome</button>
        </a>
      </div>
    </div>
  `,
  styles: [`
    .cloud-off-icon {
      color: #ccc;
      font-size: 9em;
    }
    .dialog-buttons {
      margin-bottom: 20px;
      margin-top: 40px;
    }
    .dialog-title {
      color: #000;
      font-size: 1.75em;
      font-weight: 500;
      line-height: 2em;
    }
    p {
      color: #999;
      font-size: 1.2em;
      font-weight: 300;
      line-height: 1.2em;
    }
  `],
})
export class BrowserWarningComponent {}
