import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fw-accordion',
  template: `
    <div>
      <div (click)="click.emit($event)" class="panel-default panel">
        <div [ngClass]="{'text-muted': disabled, 'fill-light-blue': selected}" class="panel-heading row">
          <div class="col-xs-1">
            <small *ngIf="chip" class="chip" width="50" height="50">
              {{ chip }}
            </small>
          </div>
          <div class="col-xs-6">
            <strong>{{ leftHeader }}</strong>
            <small *ngIf="subheader" class="text-muted">
              {{ subheader }}
            </small>
          </div>
          <div class="col-xs-4">
            <strong>
              {{ rightHeader }}
            </strong>
          </div>
          <div class="col-xs-1 text-right" *ngIf="!disabled" style="padding-right:16px;">
            <i class="fa medium-grey" (click)="toggleAccordion($event)" [ngClass]="{ 'fa-chevron-down': open, 'fa-chevron-up': !open }"></i>
          </div>
        </div>
      </div>
      <ng-content *ngIf="open" class=""></ng-content>
    </div>
  `,
  styles: [`
    .chip {
      border-radius: 20px;
      background: #D3D6DB;
      margin: 0;
      padding: 4px 7px !important;
    }
  `],
})

export class FwAccordionComponent {
  @Input() chip = '';
  @Input() disabled = false;
  @Input() rightHeader = '';
  @Input() leftHeader = '';
  @Input() selected = false;
  @Input() open = false;
  @Input() subheader = '';

  @Output() click = new EventEmitter<MouseEvent>();

  toggleAccordion($event): void {
    $event.stopPropagation();
    this.open = !this.open;
  }
}
