
    <div class="component-container">
      <h4 class="mat-h4">Saved Queries</h4>
      <div *ngIf="savedSearches$ | async; let savedSearches; else loading">
        <table mat-table *ngIf="savedSearches.length > 0; else empty" [dataSource]="savedSearches" class="fw-table data-table">
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef>Label</th>
            <td mat-cell *matCellDef="let element">{{ element.label }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="actions">
              <button data-id="saved-queries-publish-button" mat-icon-button (click)="onLoadQuery(element)" matTooltip="Load Query" matTooltipPosition="above">
                <mat-icon>publish</mat-icon>
              </button>
              <button data-id="saved-queries-delete-button" mat-icon-button (click)="onDeleteQuery(element)" matTooltip="Delete Query" matTooltipPosition="above">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="searchColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: searchColumns;"></tr>
        </table>
        <ng-template #empty>You don't have any queries saved.</ng-template>
      </div>
      <ng-template #loading>
        <div>Loading saved queries...</div>
      </ng-template>
    </div>
  